<template>
  <div>
    <div class="mb-32 mt-32 col-12">
      <div
        class="hp-bg-black-bg py-32 py-sm-64 px-24 px-sm-48 px-md-80 position-relative overflow-hidden hp-page-content"
        style="border-radius: 32px"
      >
        <svg
          width="358"
          height="336"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="position-absolute hp-rtl-scale-x-n1"
          style="bottom: 0px; right: 0px"
        >
          <path
            d="M730.404 135.471 369.675-6.641l88.802 164.001-243.179-98.8 246.364 263.281-329.128-126.619 114.698 166.726-241.68-62.446"
            stroke="url(#a)"
            stroke-width="40"
            stroke-linejoin="bevel"
          ></path>
          <defs>
            <linearGradient
              id="a"
              x1="315.467"
              y1="6.875"
              x2="397.957"
              y2="337.724"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#fff"></stop>
              <stop offset="1" stop-color="#fff" stop-opacity="0"></stop>
            </linearGradient>
          </defs>
        </svg>
        <div class="row">
          <div class="col-sm-6 col-12">
            <div class="row">
              <div class="col-12">
                <h1 class="mb-0 hp-text-color-black-0">
                  Undisputed Noobs Portal
                </h1>
              </div>
              <div class="col-12">
                <ol class="breadcrumb mt-24">
                  <li class="breadcrumb-item breadcrumb-link">
                    <a href="/" target="_self"> Home </a>
                  </li>
                  <li class="breadcrumb-item breadcrumb-link">
                    <a class="" target="_self"><span>Legal</span></a>
                  </li>
                  <li class="breadcrumb-item">
                    <a href="/legal/privacy-policy" class="" target="_self"
                      ><span class="hp-text-color-black-0">
                        Privacy Policy
                      </span></a
                    >
                  </li>
                </ol>
              </div>
              <div class="col-12">
                <p class="h5 mb-0 mt-24 hp-text-color-black-0">
                  This is our Privacy Policy.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <b-row>
          <b-col cols="12" class="mt-24">
            <h4>1. Introduction</h4>
            <p>
              1.1 We are committed to safeguarding the privacy of our website
              visitors and service users.
              <br /><br />
              1.2 This policy applies where we are acting as a data controller
              with respect to the personal data of our website visitors and
              service users; in other words, where we determine the purposes and
              means of the processing of that personal data.
              <br /><br />
              1.3 We use cookies on our website. Insofar as those cookies are
              not strictly necessary for the provision of our website and
              services, we will ask you to consent to our use of cookies when
              you first visit our website.
              <br /><br />
              1.4 Our website incorporates privacy controls which affect how we
              will process your personal data. By using the privacy controls,
              you can specify whether you would like to receive direct marketing
              communications and limit the publication of your information. You
              can access the privacy controls via
              https://member.undisputednoobs.com/profile.
              <br /><br />
              1.5 In this policy, "we", "us" and "our" refer to Undisputed
              Noobs. For more information about us, see Section 13.
            </p>
            <hr class="bg-white" />
            <h4>2. Credit</h4>
            <p>
              2.1 This document was created using a template from Docular
              (https://docular.net).
            </p>
            <hr class="bg-white" />
            <h4>3. How we use your personal data</h4>
            <p>
              3.1 In this Section 3 we have set out:
              <br />
              &nbsp; (a) the general categories of personal data that we may
              process; <br />
              &nbsp; (b) in the case of personal data that we did not obtain
              directly from you, the source and specific categories of that
              data; <br />
              &nbsp; c) the purposes for which we may process personal data; and
              <br />
              &nbsp; (d) the legal bases of the processing. <br /><br />
              3.2 We may process data about your use of our website and services
              ("usage data"). The usage data may include your IP address,
              geographical location, browser type and version, operating system,
              referral source, length of visit, page views and website
              navigation paths, as well as information about the timing,
              frequency and pattern of your service use. The source of the usage
              data is our analytics tracking system. This usage data may be
              processed for the purposes of analysing the use of the website and
              services. The legal basis for this processing is consent.
              <br /><br />
              3.3 We may process your website user account data ("account
              data"). The account data may be processed for the purposes of
              operating our website, providing our services, ensuring the
              security of our website and services, maintaining back-ups of our
              databases and communicating with you. The legal basis for this
              processing is our legitimate interests, namely the proper
              administration of our website and business.
              <br /><br />
              3.4 We may process information that you post for publication on
              our website or through our services ("publication data"). The
              publication data may be processed for the purposes of enabling
              such publication and administering our website and services. The
              legal basis for this processing is consent.
              <br /><br />
              3.5 We may process information contained in any enquiry you submit
              to us regarding goods and/or services ("enquiry data"). The
              enquiry data may be processed for the purposes of offering,
              marketing and selling relevant goods and/or services to you. The
              legal basis for this processing is consent.
              <br /><br />
              3.6 We may process information relating to transactions, including
              purchases of goods and/or services, that you enter into with us
              and/or through our website ("transaction data"). The source of the
              transaction data is you and/or our payment services provider. The
              transaction data may be processed for the purpose of supplying the
              purchased goods and/or services and keeping proper records of
              those transactions. The legal basis for this processing is the
              performance of a contract between you and us and/or taking steps,
              at your request, to enter into such a contract; providing that, if
              you are not the person contracting with us, the legal basis for
              this processing is our legitimate interests, namely the proper
              administration of our website and business.
              <br /><br />
              3.7 We may process information that you provide to us for the
              purpose of subscribing to our email notifications and/or
              newsletters ("notification data"). The notification data may be
              processed for the purposes of sending you the relevant
              notifications and/or newsletters. The legal basis for this
              processing is consent.
              <br /><br />
              3.8 We may process any of your personal data identified in this
              policy where necessary for the establishment, exercise or defence
              of legal claims, whether in court proceedings or in an
              administrative or out-of-court procedure. The legal basis for this
              processing is our legitimate interests, namely the protection and
              assertion of our legal rights, your legal rights and the legal
              rights of others.
              <br /><br />
              3.9 We may process any of your personal data identified in this
              policy where necessary for the purposes of obtaining or
              maintaining insurance coverage, managing risks, or obtaining
              professional advice. The legal basis for this processing is our
              legitimate interests, namely the proper protection of our business
              against risks.
              <br /><br />
              3.10 In addition to the specific purposes for which we may process
              your personal data set out in this Section 3, we may also process
              any of your personal data where such processing is necessary for
              compliance with a legal obligation to which we are subject, or in
              order to protect your vital interests or the vital interests of
              another natural person.
              <br /><br />
              3.11 Please do not supply any other person's personal data to us,
              unless we prompt you to do so.
            </p>
            <hr class="bg-white" />
            <h4>4. Providing your personal data to others</h4>
            <p>
              4.1 We may disclose your personal data to our insurers and/or
              professional advisers insofar as reasonably necessary for the
              purposes of obtaining or maintaining insurance coverage, managing
              risks, obtaining professional advice, or the establishment,
              exercise or defence of legal claims, whether in court proceedings
              or in an administrative or out-of-court procedure. <br /><br />
              4.2 Your personal data held in our website database will be stored
              on the servers of our hosting services providers.
              <br /><br />
              4.3 In addition to the specific disclosures of personal data set
              out in this Section 4, we may disclose your personal data where
              such disclosure is necessary for compliance with a legal
              obligation to which we are subject, or in order to protect your
              vital interests or the vital interests of another natural person.
              We may also disclose your personal data where such disclosure is
              necessary for the establishment, exercise or defence of legal
              claims, whether in court proceedings or in an administrative or
              out-of-court procedure.
            </p>
            <hr class="bg-white" />
            <h4>5. International transfers of your personal data</h4>
            <p>
              5.1 In this Section 5, we provide information about the
              circumstances in which your personal data may be transferred to
              countries outside the European Economic Area (EEA).
              <br /><br />
              5.2 The hosting facilities for our website are situated in London,
              UK. The European Commission has made an "adequacy decision" with
              respect to the data protection laws of each of this country.
              <br /><br />
              5.3 You acknowledge that personal data that you submit for
              publication through our website or services may be available, via
              the internet, around the world. We cannot prevent the use (or
              misuse) of such personal data by others.
            </p>
            <hr class="bg-white" />
            <h4>6. Retaining and deleting personal data</h4>
            <p>
              6.1 This Section 6 sets out our data retention policies and
              procedure, which are designed to help ensure that we comply with
              our legal obligations in relation to the retention and deletion of
              personal data.
              <br /><br />
              6.2 Personal data that we process for any purpose or purposes
              shall not be kept for longer than is necessary for that purpose or
              those purposes.
              <br /><br />
              6.3 We will retain your personal data as follows:
              <br />
              &nbsp; (a) usage data will be retained for a minimum period of 12
              months following the date of collection, and for a maximum period
              of 3 years following that date; <br />
              &nbsp; (b) account data will be retained for a minimum period of
              12 months following the date of closure of the relevant account,
              and for a maximum period of 3 years following that date; <br />
              &nbsp; c) enquiry data will be retained for a minimum period of 1
              months following the date of the enquiry, and for a maximum period
              of 12 months following that date; and (d) . <br /><br />
              6.4 Notwithstanding the other provisions of this Section 6, we may
              retain your personal data where such retention is necessary for
              compliance with a legal obligation to which we are subject, or in
              order to protect your vital interests or the vital interests of
              another natural person.
            </p>
            <hr class="bg-white" />
            <h4>7. Your rights</h4>
            <p>
              7.1 In this Section 7, we have listed the rights that you have
              under data protection law.
              <br /><br />
              7.2 Your principal rights under data protection law are:
              <br />
              &nbsp; (a) the right to access - you can ask for copies of your
              personal data; <br />
              &nbsp; (b) the right to rectification - you can ask us to rectify
              inaccurate personal data and to complete incomplete personal data;
              <br />
              &nbsp; c) the right to erasure - you can ask us to erase your
              personal data; <br />
              &nbsp; (d) the right to restrict processing - you can ask use to
              restrict the processing of your personal data; <br />
              &nbsp; (e) the right to object to processing - you can object to
              the processing of your personal data; <br />
              &nbsp; (f) the right to data portability - you can ask that we
              transfer your personal data to another organisation or to you;
              <br />
              &nbsp; (g) the right to complain to a supervisory authority - you
              can complain about our processing of your personal data; and
              <br />
              &nbsp; (h) the right to withdraw consent - to the extent that the
              legal basis of our processing of your personal data is consent,
              you can withdraw that consent. <br /><br />
              7.3 These rights are subject to certain limitations and
              exceptions. You can learn more about the rights of data subjects
              by visiting
              https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/.
              <br /><br />
              7.4 You may exercise any of your rights in relation to your
              personal data by written notice to us, using the contact details
              set out below.
            </p>
            <hr class="bg-white" />
            <h4>8. About cookies</h4>
            <p>
              8.1 A cookie is a file containing an identifier (a string of
              letters and numbers) that is sent by a web server to a web browser
              and is stored by the browser. The identifier is then sent back to
              the server each time the browser requests a page from the server.
              <br /><br />
              8.2 Cookies may be either "persistent" cookies or "session"
              cookies: a persistent cookie will be stored by a web browser and
              will remain valid until its set expiry date, unless deleted by the
              user before the expiry date; a session cookie, on the other hand,
              will expire at the end of the user session, when the web browser
              is closed.
              <br /><br />
              8.3 Cookies do not typically contain any information that
              personally identifies a user, but personal data that we store
              about you may be linked to the information stored in and obtained
              from cookies.
            </p>
            <hr class="bg-white" />
            <h4>9. Cookies that we use</h4>
            <p>
              9.1 We use cookies for the following purposes:
              <br />
              &nbsp; (a) authentication and status - we use cookies to identify
              you when you visit our website and as you navigate our website,
              and to determine if you are logged into the website; <br />
              &nbsp; (b) personalisation - we use cookies to store information
              about your preferences and to personalise the website for you;
              <br />
              &nbsp; c) security - we use cookies as an element of the security
              measures used to protect user accounts, including preventing
              fraudulent use of login credentials, and to protect our website
              and services generally; <br />
              &nbsp; (d) advertising - we use cookies to help us to display
              advertisements that will be relevant to you; <br />
              &nbsp; (e) analysis - we use cookies to help us to analyse the use
              and performance of our website and services; and <br />
              &nbsp; (f) cookie consent - we use cookies to store your
              preferences in relation to the use of cookies more generally.
            </p>
            <hr class="bg-white" />
            <h4>10. Cookies used by our service providers</h4>
            <p>
              10.1 Our service providers use cookies and those cookies may be
              stored on your computer when you visit our website.
              <br /><br />
              10.2 We use Google Analytics. Google Analytics gathers information
              about the use of our website by means of cookies. The information
              gathered is used to create reports about the use of our website.
              You can find out more about Google's use of information by
              visiting https://www.google.com/policies/privacy/partners/ and you
              can review Google's privacy policy at
              https://policies.google.com/privacy.
            </p>
            <hr class="bg-white" />
            <h4>11. Managing cookies</h4>
            <p>
              11.1 Most browsers allow you to refuse to accept cookies and to
              delete cookies. The methods for doing so vary from browser to
              browser, and from version to version. You can however obtain
              up-to-date information about blocking and deleting cookies via
              these links:
              <br />
              &nbsp; (a) https://support.google.com/chrome/answer/95647
              (Chrome); <br />
              &nbsp; (b)
              https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences
              (Firefox); <br />
              &nbsp; c) https://help.opera.com/en/latest/security-and-privacy/
              (Opera); <br />
              &nbsp; (d)
              https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies
              (Internet Explorer); <br />
              &nbsp; (e)
              https://support.apple.com/en-gb/guide/safari/manage-cookies-and-website-data-sfri11471/mac
              (Safari); and <br />
              &nbsp; (f)
              https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy
              (Edge). <br /><br />
              11.2 Blocking all cookies will have a negative impact upon the
              usability of many websites.
              <br /><br />
              11.3 If you block cookies, you will not be able to use all the
              features on our website.
            </p>
            <hr class="bg-white" />
            <h4>12. Amendments</h4>
            <p>
              12.1 We may update this policy from time to time by publishing a
              new version on our website.
              <br /><br />
              12.2 You should check this page occasionally to ensure you are
              happy with any changes to this policy.
              <br /><br />
              12.3 We may notify you of significant changes to this policy by
              email.
            </p>
            <hr class="bg-white" />
            <h4>13. Our details</h4>
            <p>
              13.1 This website is owned and operated by Online Mastery Limited.
              <br /><br />
              13.2 We are registered in England and Wales under registration
              number 07979858, and our registered office is at 30 Linford Drive,
              Basildon, Essex.
              <br /><br />
              13.3 You can contact us:
              <br />
              &nbsp; (a) using our website contact form.
            </p>
            <hr class="bg-white" />
            <h4>14. Data protection officer</h4>
            <p>
              14.1 Our data protection officer's contact details are: Steve
              Woody, info @ undisputednoobs.com.
            </p>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { BRow, BCol, BCard } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
  },
};
</script>
